<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Activity Logs</div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between mb-5">
      <form class="conFormSubmit conSearchForm mt-5">
        <v-row>
          <v-col cols="12" md="4">
            <label class="eachLabel">Table Names</label>
            <v-select
              v-model="fromItem.table_name"
              outlined
              :items="filterData.table_names"
              item-text="table_names"
              item-value="table_names"
              placeholder="Name"
              hide-details="true"
              :menu-props="{ bottom: true, offsetY: true }"
              attach
              clearable
              append-icon="mdi-chevron-down"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <label class="eachLabel">Log Types</label>
            <v-select
              outlined
              v-model="fromItem.log_types"
              :items="filterData.log_types"
              item-text="log_types"
              item-value="filterData.log_types"
              placeholder="types"
              hide-details="true"
              :menu-props="{ bottom: true, offsetY: true }"
              attach
              clearable
              append-icon="mdi-chevron-down"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <label class="eachLabel">User Name</label>
            <v-select
              outlined
              v-model="fromItem.id"
              :items="users"
              item-text="name"
              item-value="id"
              placeholder="User Name"
              hide-details="true"
              :menu-props="{ bottom: true, offsetY: true }"
              attach
              clearable
              append-icon="mdi-chevron-down"
            ></v-select>
          </v-col>
          <v-col md="4" cols="12" class="d-flex align-end">
            <v-btn @click="getListData()" class="btnAddPrimary"> Search </v-btn>
          </v-col>
        </v-row>
      </form>
    </div>
    <v-row v-if="listData && listData.length > 0">
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              :items-per-page="5"
              hide-default-footer
            >
              <template v-slot:[`item.request_info`]="{ item }">
                <v-expansion-panels variant="inset">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      {{ item.request_info.ip }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      {{ item.request_info.user_agent }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
              <template v-slot:[`item.data`]="{ item }">
                <div><a @click="opendataDialog(item)">More Details</a></div>
              </template>
            </v-data-table>
          </div>
        </section>
        <div
          class="conOfPagination pr-5 pt-5"
          v-if="pagination.total > pagination.per_page"
        >
          <v-pagination
            :total-visible="7"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>
    <v-dialog v-model="dataDialog" max-width="800" height="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">More Details</span>
        </v-card-title>

        <v-card-text class="conFormSubmit">
          <v-container>
            <v-row class="justify-center">
              <v-simple-table>
                <template v-if="selectedItem">
                  <thead>
                    <tr>
                      <th class="text-left">Data</th>
                      <th class="text-left">Current Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <pre>{{
                          JSON.stringify(selectedItem.data, null, 2)
                        }}</pre>
                      </td>
                      <td>
                        <pre v-if="selectedItem.current_data">{{
                          JSON.stringify(selectedItem.current_data, null, 2)
                        }}</pre>
                        <pre v-else></pre>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="dataDialog = false">
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import EmptyState from "@/modules/shared/components/emptystate";
export default {
  data: () => ({
    isLoading: false,
    type: [],
    name: [],
    dataDialog: false,
    showRequestInfo: false,
    pagination: {
      current_page: 1,
    },

    headers: [
      { text: "id", value: "id" },
      { text: "Name", value: "user.name" },

      { text: "Log Datetime", value: "log_datetime" },
      { text: "Table Name", value: "table_name" },
      { text: "Log Type", value: "log_type" },
      { text: "data", value: "data" },
      {
        text: "Request Info",
        value: "request_info",
        sortable: false,
      },
    ],
    data: [],
    listData: [],
    listDataDetaile: [],
    filterData: [],
    users: [],
    fromItem: {
      log_types: "",
      id: "",
      table_name: "",
    },
    selectedItem: null,
  }),
  components: { EmptyState },
  computed: {},
  methods: {
    opendataDialog(item) {
      this.selectedItem = item;
      this.dataDialog = true;
    },
    toggleRequestInfo(item) {
      item.showRequestInfo = !item.showRequestInfo;
    },
    goToPage() {
      this.getListData(this.pagination.current_page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      this.listData = [];
      this.listDataDetaile = {};
      this.isLoading = true;
      apiServices
        .get(
          `logs?per_page=5&page=${page}&log_type=${this.fromItem.log_types}&table_name=${this.fromItem.table_name}&user_id=${this.fromItem.id}`
        )
        .then((res) => {
          if (res) {
            this.isLoadingSearch = false;
            this.listData = res.data;
            this.listDataDetaile = this.listData.current_data;
            console.log("listData", this.listData);
            console.log("listDataDetaile", this.listDataDetaile);
            this.pagination = res.meta;
            console.log("pagination", this.pagination);

            this.isLoading = false;
          } else this.isLoading = false;
        });
    },
    getFilterData() {
      this.filterData = [];

      apiServices.get(`log/filter-option`).then((res) => {
        if (res) {
          this.isLoadingSearch = false;
          this.filterData = res.data;
          console.log("filter-option", this.filterData);

          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    getUsersData() {
      this.users = [];

      apiServices.get(`user/users`).then((res) => {
        if (res) {
          this.isLoadingSearch = false;
          this.users = res.data;
          console.log("users", this.users);

          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
  },
  created() {
    this.getListData(this.pagination.current_page);
    this.getFilterData();
    this.getUsersData();
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
::v-deep {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border-bottom: none;
  }
  .v-expansion-panel::before {
    border-radius: inherit;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: none;
  }
  .theme--light.v-expansion-panels .v-expansion-panel {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
  }
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 90%;
    overflow: auto;
    overflow-x: auto;
  }
}
</style>
